import { FC, ReactNode } from 'react';

import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import Button from '~/components/Button';
import Container from '~/components/Container';
import { styles as DividerStyles } from '~/components/Divider';
import SectionTitle from '~/components/SectionTitle';
import { styles as TextStyles } from '~/components/Text';
import { Identifiers } from '~/constants/enum';
import { QUERY_GET_CMS_BLOCKS } from '~/data/home';
import { replaceClassNames } from '~/utils/converters';
import styles from './Recommended.module.scss';
import { useRecoilState } from 'recoil';
import { cmsBlocksState } from '~/services/blocks';
import useCmsBlocks from '~/hooks/useCmsBlocks';

interface RecommendedProps {
    title?: string;
    list?: {
        title?: string;
        subtitle?: string;
        image?: string;
        cta?: ReactNode;
    }[];
    offers?: { title?: string; subtitle?: string }[];
    coupon?: string;
    directLabel?: string;
    directUrl?: string;
    cmsBlock?: string;
    skip?: boolean;
    loading?: boolean;
}

const Recommended: FC<RecommendedProps> = ({
    title = 'RECOMMENDED',
    list = [...Array(4)].map((_) => ({
        title: 'SMART STYLE',
        subtitle: 'WEEKLY UPDATE',
        image: '/images/recommended.png',
        cta: (
            <Button type="outline" variant="tertiary">
                SHOP NOW
            </Button>
        )
    })),
    offers = [...Array(3)].map((_, index) => ({
        title: `GET ${index + 1}0 OFF`,
        subtitle: 'On orders over $99'
    })),
    directLabel = 'See All',
    directUrl = '#',
    coupon = 'FREESHIPPING',
    cmsBlock,
    skip
}) => {
    const { cmsBlocks, loading } = useCmsBlocks([Identifiers.Recommended]);

    const _content = cmsBlocks[Identifiers.Recommended]?.content;
    const _title = cmsBlocks[Identifiers.Recommended]?.title;

    const compileStyle = {
        Recommended: styles,
        Text: TextStyles,
        Divider: DividerStyles
    };
    const _loading = loading;

    if (!_loading && !_content) return null;

    return (
        <Container rootClassName={styles.recommended_wrapper} className={styles.recommended}>
            <div className="flex flex-col gap-5">
                <SectionTitle centered text={_title} />
                {/* {directUrl && (
          <Link href={directUrl} className={styles.cta}>
            <Text type="title-1">{directLabel}</Text>
            <Icon name="arrow-up-right" size={24} />
          </Link>
        )} */}
            </div>
            {!!_content ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: replaceClassNames(_content, compileStyle)
                    }}
                />
            ) : (
                <div className={styles.recommended_list}>
                    {Array.isArray(list) &&
                        list.map((item, index) => (
                            <div key={index} className={styles.recommended_item}>
                                <div className="relative w-full h-full">
                                    <Skeleton className="aspect-square" />
                                </div>

                                <div className={styles.content_box}>
                                    <Skeleton className="w-full" />

                                    {/* This title varies in font-size, therefore cannot use Text component */}
                                    <p className={styles.title}>
                                        <Skeleton className="w-full" />
                                    </p>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </Container>
    );
};

export default Recommended;
