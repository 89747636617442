import { FC, ReactNode } from "react";
import Text, { TextProps } from "../Text";
import classNames from "classnames";
import Divider from "../Divider";
import Colors from "~/constants/colors";
import styles from "./SectionTitle.module.scss";
import { getConfig } from "~/utils/theme";

interface SectionTitleProps {
  text?: string;
  textProps?: TextProps;
  action?: ReactNode;
  className?: string;
  dividerColor?: string;
  centered?: boolean;
}

const SectionTitle: FC<SectionTitleProps> = ({
  text,
  textProps,
  action,
  dividerColor = Colors.DIVIDER_COLOR,
  className,
  centered = false,
}) => {
  const themeConfig = getConfig("Section");
  return (
    <div
      className={classNames(
        "flex flex-col mb-2 ",
        styles.section_title,
        className
      )}
    >
      <div className="flex flex-wrap justify-between items-center gap-6">
        {centered && (
          <Divider dividerClassName="flex-1" dividerColor={dividerColor} />
        )}
        <Text
          type="headline-4"
          {...textProps}
          className={classNames(styles.content, textProps?.className)}
        >
          {text?.toLowerCase()}
        </Text>
        {(themeConfig.dividerPlacement === "embed" || centered) && (
          <Divider dividerClassName="flex-1" dividerColor={dividerColor} />
        )}

        {action}
      </div>

      {themeConfig.dividerPlacement === "bottom" && !centered && (
        <Divider dividerColor={dividerColor} />
      )}
    </div>
  );
};

export default SectionTitle;
